body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 280px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: none;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: Pretendard, Apple SD Gothic Neo, Sans-serif;
}

@media (max-width: 1600px) {
  * {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  * {
    font-size: 12px;
  }
}

@media (max-width: 1084px) {
  * {
    font-size: 10px;
  }
}

@media (max-width: 800px) {
  * {
    font-size: 6px;
  }
}

@media (max-width: 450px) {
  * {
    font-size: 4px;
  }
}

@media (max-width: 320px) {
  * {
    font-size: 2px;
  }
}

@media screen and (min-device-aspect-ratio: 16/9) and (max-width: 1920px) {
  * {
    font-size: 13px;
  }
}

@media screen and (min-device-aspect-ratio: 16 / 9) and (max-width: 1400px) {
  * {
    font-size: 10px;
  }
}

@media screen and (min-device-aspect-ratio: 16 / 9) and (max-width: 1200px) {
  * {
    font-size: 10px;
  }
}
