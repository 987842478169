.container {
  background: #1170ff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  color: #fff;
  padding: 0.5em 1em;
  border-radius: 30px;
  font-weight: 700;
  font-size: 1rem;

  padding-left: 0.5rem;
}

.marker {
  min-width: 10px;
  min-height: 10px;

  width: 1.25rem;
  height: 1.25rem;

  background: #fff;
  border-radius: 50%;
  padding: 0.3em;
}
